.quote {
    display: block;
    overflow: hidden;
    margin: 30px auto;
}

.content {
    display: flex;
    justify-content: center;
}

.leftQuote{
    transform: translateY(-50%);
    margin-right: 10px;
}

.rightQuote{
    transform: translateY(20%);
    margin-left: 10px;
}

.author {
    transform: translateX(25%);
}

@media (max-device-width: 680px) {
    .content {
        font-size: 90%;
    }

    .author {
        font-size: 90%
    }
}

@media (max-device-width: 430px) {
    .content {
        font-size: 90%;
    }

    .author {
        font-size: 80%;
        transform: translateX(15%);
    }
}