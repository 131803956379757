.linkButton {
    display: block;
    
    width: 140px;
    max-width: 140px;
    height: 46px;
    border-color: #d4af3a;
    border-width: 4px;
    border-radius: 5%;
    border-style: solid;
    margin: 12px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    
}

.linkText {
    font-family: 'Krete', serif;
    font-size: 24px;
    font-style: italic;
    text-decoration: none;
    color: #d4af3a;
    transition: color 0.5s
}

.linkTextHover {
    font-family: 'Krete', serif;
    font-size: 24px;
    font-style: italic;
    text-decoration: none;
    color: #fdfffa;
    transition: color 0.5s
}


.buttonBox {
    max-width: 180px;
    position: relative;
    padding: 0 10px;
    box-sizing: content-box;
    margin: 12px auto;
    display: flex;
    align-items: center;
}

.animationBar1{
    position: absolute;
    top: 0;
    left: 20%;
    width: 60%;
    height: 2px;
    background-color: #d4af3a;
    transform: scalex(0);
    transition: transform 0.5s;
    transform-origin: right;
 }
 
 .animationBar2 {
    position: absolute;
    top: 25%;
    left: 0;
    width: 2px;
    height: 50%;
    background-color: #d4af3a;
    transform: scaley(0);
    transition: transform 0.5s;
    transform-origin: bottom;
 }
 
 .animationBar3 {
    position: absolute;
    top: 96%;
    left: 20%;
    width: 60%;
    height: 2px;
    background-color: #d4af3a;
    transform: scalex(0);
    transition: transform 0.5s;
    transform-origin: left;
 }
 
 .animationBar4 {
    position: absolute;
    top: 25;
    left: 98%;
    width: 2px;
    height: 50%;
    background-color: #d4af3a;
    transform: scaley(0);
    transition: transform 0.5s;
    transform-origin: top;
 }

 .animationBarActivated1{
    position: absolute;
    top: 0;
    left: 20%;
    width: 60%;
    height: 2px;
    background-color: #d4af3a;
    transform: scalex(1);
    transition: transform 0.5s;
    transform-origin: left;
 }
 
 .animationBarActivated2{
    position: absolute;
    top: 25%;
    left: 0;
    width: 2px;
    height: 50%;
    background-color: #d4af3a;
    transform: scaley(1);
    transition: transform 0.5s;
    transform-origin: top;
 }
 
 .animationBarActivated3 {
    position: absolute;
    top: 96%;
    left: 20%;
    width: 60%;
    height: 2px;
    background-color: #d4af3a;
    transform: scalex(1);
    transition: transform 0.5s;
    transform-origin: right;
 }
 
 .animationBarActivated4{
    position: absolute;
    top: 25;
    left: 98%;
    width: 2px;
    height: 50%;
    background-color: #d4af3a;
    transform: scaley(1);
    transition: transform 0.5s;
    transform-origin: bottom;
 }

 @media only screen and (max-device-width: 1024px) {
   a.linkText {
      font-size: 20px;
   }

   @media (hover: none) and (pointer: coarse) {
      .buttonHover {
        color: inherit;
        transition: none;
      }
    
      .animationBar1, .animationBar2, .animationBar3, .animationBar4,
      .animationBarActivated1, .animationBarActivated2, .animationBarActivated3, .animationBarActivated4 {
        transition: none;
        transform: none;
      }
    }
 }