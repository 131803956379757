.socialNav {
    width: 128px;
    height: 128px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: start;
}

.socialNavIcon {

}

@media (max-device-width: 1024px) {
    .socialNav {
        width: 80px;
        height: 80px;
        margin: 0 80px 20px;    }

    .socialNavIcon {
        width: 36px;
        height: 36px;
        margin: 4px;
    }
}

@media (max-device-width: 430px) {
    .socialNav {
        margin : 18px 0 0;
    }

    .socialNavIcon {
        width: 32px;
        height: 32px;
        margin: 0 12px;
    }
}

