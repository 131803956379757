main {
    background-color: #FDFFFA;
    width: 100%;
    box-sizing: border-box;
    padding: 48px 48px 24px;
    column-gap: 0px;
    overflow: scroll;
}

.nextConcerts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 48px;
    overflow: hidden;
    height: 1000px;
    padding: 0;
    width: 90%;
    margin: 0 auto;
}

.bilder {
    width: 100%;
}

.musikerBildContainer {
    width: 100%;
    overflow: hidden;
}

.musikerBild {
    object-fit: cover;
    width: 100%;
    height: 974px;
}

.lastProject {
    margin: 0 auto 40px;
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0px;
    padding: 0;
    width: 90%;
}

.projectTitle {
    width: 60%;
    color: #FDFFFA;
}

.projectSubtitle {
    color: #fdfffa; 
}

.projectText {
    width: 70%;
    color: #FDFFFA;
    margin: auto;
}

.lastNewsWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items:flex-start;
}

.lastNewsBild {
    display: block;
    width: 40%;
    height: 40%;
}

.lastNewsItem {
    padding: 0 10px;
    width: 50%;
}

@media (max-device-width: 1024px) {
   
    .nextConcerts {
        display: block;
        min-height: 1100px !important;
        padding: 40px 0; 
        
    }

    .lastProject {
        display: block;
    }

    .lastNewsWrapper {
        display: block;
    }

    .lastNewsBild {
        width: 100%;
        height: 100%;
    }

    .lastNewsItem {
        width: 100%;
        margin-top: 20px;
    }

    .projectSubtitle {
        font-size: 1.1em;
    }
}