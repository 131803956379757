.entrypage,
.entrypageClosing {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #0c2856;

    background-repeat: no-repeat;
    background-size: cover;
    z-index: 100000001;
    transition: transform 500ms ease-in;
}

.entrypage {
    transform: translateX(0);
}

.entrypageClosing {
    transform: translateX(-100%);
   }

.bgImage {
    background-image: url('../img/savignano-entrypage.jpg');
}

.entryImg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.logo_Francesco {
    font-family: 'p22-underground-sc', sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 68px;
    color: #d4af3a;
    text-align: center;
    margin: 0;
    padding: 0;
    width: 460px;
}

.logo_Savignano {
    font-family: 'p22-underground-sc', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 80px;
    color: #fdfffa;
    text-decoration: underline;
    text-align: center;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 520px;
    align-self: flex-end;
}

.title {
    background-color: #d4b03a31;
    margin: 30% auto 0;
    width: 60%;
    height: 200px;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    animation: enterPage 1000ms forwards;
}

.buttonContainer {
    position: fixed;
    bottom: 10%;
    right: 10%;
    animation: enterPage 1000ms forwards;
}

@keyframes enterPage {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media only screen and (max-device-width: 1024px) and (min-device-width: 431px){
    .title {
        margin: 40% auto 0;
        transform: translateY(200px);
    }

    .logo_Francesco {
        font-size: 3rem;
    }

    .logo_Savignano {
        font-size: 3.5rem;
    }

}

@media only screen and (max-device-width: 430px) {

    .entrypage {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-image: url('../img/savignano-entrypage-mobile.png');
        background-repeat: no-repeat;
        background-size: cover;
        transform: translateX(0);
    
        z-index: 10000000;
    
    }
    
    .entrypageClosing {
    
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-image: url('../img/savignano-entrypage-mobile.png');
        background-repeat: no-repeat;
        background-size: cover;
        transform: translateX(-100%);
        z-index: 10000000;
        transition: transform 400ms ease-in;
    
    }

    .title {
        
        margin: 80% auto 0;
        padding: 0 20px;
        width: 100vw;
        height: 140px;
        background-color: #d4b03a65;
       
    }

    .logo_Francesco {
      
        font-size: 2rem;
        transform: translateX(24px);
      /*   color: #fdfffa; */
       
        
    }

    .logo_Savignano {
        
        font-size: 2.5rem;
        
    }

}

@media only screen and (max-device-width: 430px) and (min-device-height: 890px) {
    .title {
        margin: 100% auto 0;
    }


    .logo_Francesco {
      
        font-size: 2.5rem;
      /*   color: #fdfffa; */
       
        
    }

    .logo_Savignano {
        
        font-size: 3rem;
        
    }
}