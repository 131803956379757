.container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.linksBox {
    display: block;
    background-color: #0c2856;
    width: 60vw;
    height: 80vh;
    z-index: 101;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: openingLinksBox 500ms ease-out forwards;
}

.linksBoxClosing {
    display: block;
    background-color: #0c2856;
    width: 60vw;
    height: 80vh;
    z-index: 101;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: closingLinksBox 500ms ease-out forwards;
}

.close {
    position: fixed;
    color: #fdfffa;
    top: 20px;
    right: 20px;
}

.close:hover {
    cursor: pointer;
}

.title {
    color: #fdfffa;
    font-family: 'p22-underground-sc', sans-serif;
    font-style: normal;
    font-weight: 900;

}

.title::first-letter{
    font-size: 120%;
}

.link {
    display: block;
    font-family: 'Krete', serif;
        font-size: 18px;
        text-align: left;
        text-decoration: none;
        color: #fdfffa;
        margin-bottom: 12px;
}


.lastNewsWrapper {
    width: 100%;
    /* display: flex;
    justify-content: space-between;
    align-items:flex-start; */
}

.lastNewsBild {
    display: inline;
    float: left;
    width: 40%;
    height: 40%;
    margin: 20px;
}

.lastNewsItem {
    padding: 0 10px;
    width: 90%;

}

@media (max-device-width: 430px) {
   
    .nextConcerts {
        display: block;
        
    }

    .lastProject {
        display: block;
    }

    .lastNewsWrapper {
        display: block;
    }

    .lastNewsBild {
        width: 100%;
        height: 100%;
    }

    .lastNewsItem {
        width: 100%;
        margin-top: 20px;
    }
}

.projectText {

    color: #FDFFFA;
    margin: auto;
}

@keyframes openingLinksBox {
    0% {
        opacity: 0;
        transform: translateY(-50%);

    }

    60% {
        opacity: 1;
        transform: translateY(10%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes closingLinksBox {
    0% {
        opacity: 1;
        transform: translateY(0);

    }

    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}

@media (max-device-width: 430px) {

    .lastNewsWrapper {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: 0 auto;
    }

    .lastNewsWrapper h3 {
        text-align: center;
    }

    .lastNewsBild {
        display: block;
        max-width: 95%;
        margin: 20px auto;
    }

    .linksBox {
        width: 90%;
        overflow: scroll;
        
    }

    .linksBoxClosing {
        width: 90vw;
    }



    .title {
        font-size: 1.5em;
        margin-top: 24px;
        text-align: center;
    }

    .projectText {
        width: 90%;
        margin: auto;
    }
}