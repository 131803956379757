.sliderMenu {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: #0c2856;
    overflow: hidden !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
    box-sizing: border-box;
    transform: translateX(-100%);
    }

.sliderMenu::-webkit-scrollbar {
    display: none;
}

.sliderMenu ul {
    padding: 0;
}

.sliderMenu-enter {
width: 100vw;
height: 100vh;
position: fixed;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
z-index: 10;
background-color: #0c2856;
overflow: hidden;
box-sizing: border-box;
transform: translateX(-100%);
}

.sliderMenu-enter-active {
box-sizing: border-box;
transform: translateX(0%);
transition: transform 400ms ease-out;
}

.sliderMenu-enter-done {
    width: 100vw !important;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
    background-color: #0c2856;
    overflow: hidden;
    box-sizing: border-box;
    transform: translateX(0%);
}

.sliderMenu-exit {
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
    background-color: #0c2856;
    overflow: hidden;
    box-sizing: border-box;
transform: translateX(0%);
}

.sliderMenu-exit-active {
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
    background-color: #0c2856;
    overflow: hidden;
    box-sizing: border-box;
transform: translateX(-100%);
transition: transform 400ms ease-in;
}

.sliderMenu-exit-done {
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
    background-color: #0c2856;
    overflow: hidden;
    box-sizing: border-box;
    transform: translate(-100%);
}


.sliderLink {
    color: #fdfffa;
    text-decoration: none;
    font-family: 'trajan-pro-3', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-align: left;
    line-height: 2em;
}