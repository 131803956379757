.list {
    overflow:hidden;
}

.subNav_box {
    min-height: 200px;
    border-left: 4px solid #d4af3a;
    padding-left: 24px;
}

.subNav {
    list-style: none;
    font-family: 'trajan-pro-3', serif;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    margin: 0;
}

.mainLink {
    color: #fdfffa;
    text-decoration: none;
    font-family: 'trajan-pro-3', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-align: left;
    margin: 0;
}

.subNav p:hover {
    cursor: pointer;
}

@media only screen and ((max-device-width: 1024px) and (min-device-width: 431px)) {

    .subNav {
        width: 140px;
        margin: 0;
        }

    .subnav .list p.mainlink {
        width: 140px;
        font-size: 16px !important;
    }
    
    .list {
        width: 140px;
    }

    .subNav_box {
        width: 140px;
        min-height: 200px;
        padding: 0;
        margin: 0 48px 0 0; 
        transform: translateX(-20%);
    } 

    .mainLink {
        color: #fdfffa;
        text-decoration: none;
        width: 140px;
    }
}

@media only screen and (max-device-width: 430px) {

    .navigations {
        width: 100%;
    }
    .subNav_box {
        width: 100px;
    }

    .list {
        font-family: 'Krete', serif;
        font-size: 14px;
        text-align: left;

    }
    .mainLink {
        font-size: 18px;
    }

    .subNav_box {
        margin-top: 12px;
        border: none;
        width: 160px;
    }
}