.scrollHeader {
    width: 100vw;
    max-height: 200px;
    background-color: #0c2856;
    box-sizing: border-box;
    display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   overflow: hidden;
   margin: 0;
   padding-top: 10px;
   position: fixed;
   top: 0px;
   left: 0;
   z-index: 30;
}

.scrollHeader-enter {
    width: 100vw;
    max-height: 200px;
    background-color: #0c2856;
    box-sizing: border-box;
    display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   overflow: hidden;
   margin: 0;
   padding-top: 10px;
   position: fixed;
   top: -200px;
   left: 0;
   z-index: 30;
}

.scrollHeader-enter-active {
    width: 100vw;
    max-height: 200px;
    background-color: #0c2856;
    box-sizing: border-box;
    display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   overflow: hidden;
   margin: 0;
   padding-top: 10px;
   position: fixed;
   top: 0px;
   left: 0;
   z-index: 30;
   transition: top 300ms;
}

.scrollHeader-enter-done {
    width: 100vw;
    max-height: 200px;
    background-color: #0c2856;
    box-sizing: border-box;
    display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   overflow: hidden;
   margin: 0;
   padding-top: 10px;
   position: fixed;
   top: 0px;
   left: 0;
   z-index: 30;
}

.scrollHeader-exit {
    width: 100vw;
    max-height: 200px;
    background-color: #0c2856;
    box-sizing: border-box;
    display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   overflow: hidden;
   margin: 0;
   padding-top: 10px;
   position: fixed;
   top: 0px;
   left: 0;
}

.scrollHeader-exit-active {
    width: 100vw;
    max-height: 200px;
    background-color: #0c2856;
    box-sizing: border-box;
    display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   overflow: hidden;
   margin: 0;
   padding-top: 10px;
   position: fixed;
   top: -200px;
   left: 0;
   z-index: 30;
   transition: top 300ms;
}

.headerStaticProperties {}

.navigations {
    display: flex;
     flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    width: 90%;
    margin-top: 20px;
}

.title {
    width: 50%;
    overflow: hidden;
}
.logo_Francesco {
    font-family: 'p22-underground-sc', sans-serif;
    max-width: 30vw;
    font-style: normal;
    font-weight: 200;
    font-size: 42px;
    color: #d4af3a;
    transform: translateX(-10%);
    text-align: center;
    margin: 0;
}

.logo_Savignano {
    max-width: 30vw;
    font-family: 'p22-underground-sc', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    color: #fdfffa;
    text-decoration: underline;
    text-align: center;
    overflow: hidden;
    margin: 0;
    transform: translateX(10%);

}