
.nav_icon1 {
    width: 60px;
    height: 45px;
    position: relative;
    margin: 8px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 10000;
  }
  
  .nav_icon1 span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #d4af3a;
    border-radius: 4px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    z-index: 10000;
  }
  
  .nav_icon1 span:nth-child(1) {
    top: 0px;
    z-index: 10000;
  }
  
  .nav_icon1 span:nth-child(2) {
    top: 16px;
    z-index: 10000;
  }
  
  .nav_icon1 span:nth-child(3) {
    top: 32px;
    z-index: 10000;
  }
  
  .nav_icon1.open span:nth-child(1) {
    top: 16px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: translate(-16px, 30px) rotate(135deg);
    position: fixed;
    z-index: 10000;
  }
  
  .nav_icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
    transform: translate(-16px, 30px);
    position: fixed;
    z-index: 10000;

  }
  
  .nav_icon1.open span:nth-child(3) {
    top: 16px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: translate(-16px, 30px) rotate(-135deg) ;
    position: fixed;
    right: 5%;
    z-index: 10000;

  }