

.main {
    background-color: #FDFFFA;
    width: 100%;
    min-height: 600px;
    padding: 0 48px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10%;
    padding: 48px 48px 24px;
    margin: 0 auto;
    
}

.content {
    line-height: 2;
    height: 100%;
    display: flex;
}

.separator {
    display: block;
    margin: 24px auto 32px;
}

.linkButton {
    display: block;
    min-width: 48px;
    max-width: 100px;
    height: 46px;
    border-color: #d4af3a;
    border-width: 4px;
    border-radius: 5%;
    border-style: solid;
    margin: 24px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Krete', serif;
    font-size: 24px;
    font-style: italic;
    text-decoration: none;
    color: #FDFFFA
}

.linkButton:active {
  
    color: #FDFFFA
}

.linkText {
    font-family: 'Krete', serif;
    font-size: 24px;
    font-style: italic;
    text-decoration: none;
    color: #080f19

}

.musikerBildContainer {
    width: 100%;
    overflow: hidden;
}

.musikerBild {

    height: 1200px;
    object-fit: cover;
    width: 100%;
    margin: 0 auto ;

}

@media (max-device-width: 1024px) {
    .main {
        display: block;
    }


}


