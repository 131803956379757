.concert {
    color: #fdfffa;
    margin-bottom: 30px;
}

.datum, .ort, .ensemble, .stadt {
    text-align: center;
    margin-bottom: 0;
    line-height: 1.2em;
}

.ort:last-of-type {
    margin-bottom: 10px;
    padding-bottom: 20px;
}

.titel {
    text-align: center;
    margin-bottom: 0;
    line-height: 1em;
    font-size: 1.1em
}

.datum {
    text-decoration: underline;
    padding-top: 12px;
}

.ort, .ensemble {
    font-style: normal;
}

.stadt {
    font-style: italic;
}

.titel {
    font-weight: 800;
}

@media only screen and (max-device-width: 430px) {
    .concert {
        margin-bottom: 20px;
    }

    .datum, .ort, .ensemble, .stadt {
        text-align: center;
        margin-bottom: 0;
        line-height: 1.1em;
    }

    .ort:last-of-type {
        margin-bottom: 10px;
        padding-bottom: 20px;
    }

    .titel {
        text-align: center;
        margin-bottom: 0;
        line-height: 1.2em;
        font-size: 1.1em
    }

    .datum {
        text-decoration: underline;
        padding-top: 12px;
    }

    .ort, .ensemble {
        font-style: normal;
    }

    .stadt {
        font-style: italic;
    }

    .titel {
        font-weight: 800;
    }
}