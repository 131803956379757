.mobileNav {
    display: flex;
    margin: 0;
    justify-content: space-between;
}

.menuTitle {
    color: #d4af30;
    text-decoration: none;
    text-align: left;
    margin: 0 20px;
    padding: 0 20px;
    font-size: 32px;
}

@media (max-device-width: 430px) {
    .mobileNav {
        margin: 10px 0;
    }
    .menuTitle {
        margin: 0 0 0 20px;
    }
}