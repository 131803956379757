.container {
    margin-top: 20px;
    padding-top: 20px;
    transform: translateY(60px);
}

.navigationNumbers {
    color: white;
    margin-right: 12px;
    text-decoration: solid;
    cursor:pointer;
    font-family: 'Krete', serif;
    font-size: 24px;
    cursor: pointer;
}

.navigationNumbersActive {
    color: #d4af3a;
    margin-right: 12px;
    text-decoration: solid;
    font-family: 'Krete', serif;
    font-size: 24px;
    cursor: pointer;
}

.arrowBack, .arrowForward {
    color: #fdfffa;
    transform: translateY(5px);
    cursor:pointer;
}

.arrowBack {
    margin-right: 12px;
}





