.blocks {
    margin: 20px auto 0;
   
}

.separation_line_horizontal {
    height: 4px;
    width: 80%;
    background-color: #d4af3a;
    border: 0;
    margin: 20px auto;
    text-align: center;
}

.address {
    color: #fdfffa;
    text-align: left;
    line-height: 160%;
}

.addressLine {
    font-size: 24px;
}

footer {
    width: 100%;
    padding: 20px 5%;
    min-height: 372px;
    background-color: #0c2856;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.logo_Francesco {
    font-family: 'p22-underground-sc', sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 38px;
    color: #d4af3a;
    text-align: left;
    margin: 0;
}

.logo_Savignano {
    font-family: 'p22-underground-sc', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 52px;
    color: #fdfffa;
    text-align: left;
    transform: translateX(20%);
    margin: 0;
}

@media (max-device-width: 1024px) and (min-device-width: 431px) {

    footer {
        grid-template-columns: repeat(3, 1fr);
        overflow: hidden;
    }

 
    .addressLine {
        margin-left: 24px;
        font-size: 20px;
        line-height: 60%;
    }
}

@media only screen and (max-device-width: 430px) {

    footer {
        grid-template-columns: repeat(2, 1fr);
        max-height: 800px; 
        overflow: hidden; 
        padding: 0
    }

    .blocks{
        padding: 10px;
    }

    .navigations {
        display: flex;
        width: 100vw;
        justify-content: center;
        margin-top: 40px;
    }

    .logo_Francesco {
        font-size: 2em;
        text-align: center;
        transform: translateX(-10%);
    }
    .logo_Savignano {
        font-size: 2em;
        text-align: center;
        max-width: 100%;
        transform: translateX(5%);
    }
    .blocks {
        overflow: hidden;
        width: 100vw;
    }

    .address {
        overflow: hidden;
        margin: 0;
        padding: 0;
        width: 100vw;
    }

    .address h5 {
        margin: 16px auto;
        font-size: 1em;
        text-align: center;
    }


}