.news {
    color: #fdfffa;
    width: 100%;
    display: flex;
    align-items: center;
}

 .titel {
    text-align: left;
    line-height: 0.7;
    font-weight: 800;
}



