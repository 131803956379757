
.mainNav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    list-style: none;
    width: 60vw;
    font-family: 'trajan-pro-3', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    margin: 0;
    position: relative;
    width: 600px;

}

.mainLink {
    color: #fdfffa;
    text-decoration: none;
    text-align: left;
}

.mainLinkActive {
    color: #d4af3a;
    text-decoration: none;
    text-align: left;
}

.pallinaMenuOut {
    width: 10px;
    background-color: #d4af3a;
    content: "";
    border-radius: 50%;
    height: 10px;
    position: absolute;
    top: 48px;
    left: 120%;
    opacity: 0;
    transform: translateX(-50%);
    transition: all 500ms ease-out;
}

.pallinaMenuHover{
    width: 10px;
    background-color: #d4af3a;
    content: "";
    border-radius: 50%;
    height: 10px;
    position: absolute;
    top: 48px;
    left: 12%;
    opacity: 1;
    transform: translateX(-50%);
    transition: all 500ms ease-out;
}

.pallinaLinkHover1{
    width: 10px;
    background-color: #d4af3a;
    content: "";
    border-radius: 50%;
    height: 10px;
    position: absolute;
    top: 48px;
    left: 12%;
    opacity: 1;
    transform: translateX(-50%);
    transition: left 500ms ease-out;
}

.pallinaLinkHover2{
    width: 10px;
    background-color: #d4af3a;
    content: "";
    border-radius: 50%;
    height: 10px;
    position: absolute;
    top: 48px;
    left: 36%;
    opacity: 1;
    transform: translateX(-50%);
    transition: left 500ms ease-out;
}

.pallinaLinkHover3{
    width: 10px;
    background-color: #d4af3a;
    content: "";
    border-radius: 50%;
    height: 10px;
    position: absolute;
    top: 48px;
    left: 63%;

    opacity: 1;
    transform: translateX(-50%);
    transition: left 500ms ease-out;
}

.pallinaLinkHover4{
    width: 10px;
    background-color: #d4af3a;
    content: "";
    border-radius: 50%;
    height: 10px;
    position: absolute;
    top: 48px;
    left: 94%;

    opacity: 1;
    transform: translateX(-50%);
    transition: left 500ms ease-out;
}