.concert {
    color: #fdfffa;
    margin-bottom: 30px;
    width: 100%;
    text-align: left;

}

.datum, .titel {
    text-align: left;
    margin-bottom: 0;
    line-height: 0.7;

    text-align: left;
}
.datum {
    text-decoration: underline;
}


.titel {
    font-weight: 800;
}