.container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.singleConcertContainer {
    width: 60%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

.close {
    position: fixed;
    color: #fdfffa;
    top: 20px;
    right: 20px;
}

.button {
    display: block;
    min-width: 48px;
    max-width: 200px;
    height: 46px;
    color: #fdfffa;
    background-color: transparent;
    border-color: #d4af3a;
    border-width: 4px;
    border-radius: 5%;
    border-style: solid;
    margin: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    font-family: 'Krete', serif;
    font-size: 24px;
    font-style: italic;
    text-decoration: none;
    cursor:pointer;
}

.titel {
    text-align: center;
    color: #fdfffa;
}

.response {
    position: fixed;
    top: 12px;
    left: 35%;
    color: lightgreen;
    font-size: 32px;
    text-transform: capitalize;
}