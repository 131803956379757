

.nextConcerts {

    background-color: #FDFFFA;
    width: 100%;
    min-height: 600px;
    padding: 40px 48px 0;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 48px;
    overflow: hidden;
    padding: 0;
    width: 90%;
    margin: 48px auto;
}

.buttons {
    display: flex;
    width: 90%;
    margin: 0 auto;
    position: relative;
    top: -20px;
}

.navigator {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 10% !important;
    margin: 0;
    padding: 0;
}

.musikerBildContainer {
    min-height: 1200px;
}

.musikerBild {
    max-width: 600px;
    max-height: 400px;
}

.swipeRightToLeft {
    animation: swipeToLeft 1s ease-in-out forwards;
}

@keyframes swipeToLeft {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    50% {
        transform: translateX(-100%);
        opacity: 0;
    }

    51% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }

}

@media (max-device-width: 1024px) {

    .nextConcerts {
        display: block;
        min-height: 800px;
    }

    .buttons {
        width: 100%;
        margin: 0 auto;
    }

    .navigator {
        bottom: 3%;
    }
}

