.container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close {
    position: fixed;
    color: #fdfffa;
    top: 20px;
    right: 20px;
}

.close:hover {
    cursor: pointer;
}

.form {
    display: block;
    background-color: #0c2856;
    width: 60vw;
    height: 80vh;
    z-index: 101;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    color: #fdfffa;
    font-family: 'p22-underground-sc', sans-serif;
    font-style: normal;
    font-weight: 900;

}

.title::first-letter{
    font-size: 120%;
}

.label {
    display: block;
    color: #fdfffa;
    font-family: 'trajan-pro-3', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    text-align: center;
}

.input {
    display: block;
    height: 42px;
    width: 260px;
    background-color: #fdfffa;
    box-shadow: inset 0 0 10px #080f19;
    margin-bottom: 14px;
    font-family: 'Krete', serif;
    font-size: 18px;
    text-align: left;
    padding: 4px 10px;
}

.message {
    display: block;
    height: 238px;
    width: 50%;
    background-color: #fdfffa;
    box-shadow: inset 0 0 10px #080f19;
    font-family: 'Krete', serif;
    font-size: 18px;
    text-align: left;
    padding: 10px 10px;

}

.button {
    display: block;
    min-width: 48px;
    max-width: 140px;
    height: 46px;
    color: #fdfffa;
    background-color: transparent;
    border-color: #d4af3a;
    border-width: 4px;
    border-radius: 5%;
    border-style: solid;
    margin: 24px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    font-family: 'Krete', serif;
    font-size: 24px;
    font-style: italic;
    text-decoration: none;
}

.buttons {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}



