.container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.impressumBox {
    display: block;
    background-color: #0c2856;
    width: 60vw;
    height: 80vh;
    z-index: 101;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: scroll;
    animation: openingImpressumBox 500ms ease-out forwards;

}

.impressumBoxClosing {
    display: block;
    background-color: #0c2856;
    width: 60vw;
    height: 80vh;
    z-index: 101;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: closingImpressumBox 500ms ease-out forwards;
}

.close {
    position: fixed;
    color: #fdfffa;
    top: 20px;
    right: 20px;
}

.close:hover {
    cursor: pointer;
}

.title {
    color: #fdfffa;
    font-family: 'p22-underground-sc', sans-serif;
    font-style: normal;
    font-weight: 900;

}

.title::first-letter{
    font-size: 120%;
}

.link {
    display: block;
    font-family: 'Krete', serif;
        font-size: 18px;
        text-align: left;
        text-decoration: none;
        color: #fdfffa;
        margin-bottom: 12px;
}

li {
    list-style: none;
}

.impressum {
    width: 80%;
    margin: 0 auto;
    color: #fdfffa;
}

@keyframes openingImpressumBox {
    0% {
        opacity: 0;
        transform: translateY(-50%);

    }

    60% {
        opacity: 1;
        transform: translateY(10%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes closingImpressumBox {
    0% {
        opacity: 1;
        transform: translateY(0);

    }

    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}

@media (max-device-width: 430px) {
    .impressumBox {
        width: 95vw;
        
    }

    .impressumBoxClosing {
        width: 95vw;
    }


    .title {
        font-size: 1.5em;
        margin-top: 24px;
    }
}