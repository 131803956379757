    header {
        overflow: hidden; 
    }

    .header {
        width: 100%;
        min-height: 500px;
        background-image: url('../img/francesco_savignano_header.png');
        background-size: cover;
        background-position: right;
        /* background-color: #0c2856; */
        box-sizing: border-box;
        display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    margin: 0;
    padding-top: 20px;
    }

    .navigations {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        width: 100vw;
        margin-top: 20px;
    }


    .logo_Francesco {
        font-family: 'p22-underground-sc', sans-serif;
        font-style: normal;
        font-weight: 200;
        font-size: 68px;
        color: #d4af3a;
        transform: translateX(-10%);
        text-align: center;
        margin: 0;
        padding: 0;
        width: 460px;
    }

    .logo_Savignano {
        font-family: 'p22-underground-sc', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 80px;
        color: #fdfffa;
        text-decoration: underline;
        text-align: center;
        overflow: hidden;
        margin: 0;
        padding: 0;
        transform: translateX(10%);
        width: 520px;
    }


    @media only screen and (max-device-width: 1024px) and (min-device-width: 431px) {


        .header {
            min-height: 280px;
            padding: 0;
        }

        .navigations {
            background-color: #d4b03a31;
            max-height: 52px;
            padding: 20px 0;
            margin: 0;
        }


        .logo_Francesco {
            font-size: 40px;
            width: 230px;
        
        
        }

        .logo_Savignano {
            font-size: 48px;
            width: 260px;
            
        }

        .title {
            margin: 40px 100px 0 20px;
        }

    }

    @media only screen and (max-device-width: 430px) {

        .navigations {
            justify-content: space-around;
            margin: 0 auto;
        }

        .header {
            background-position: 90% 0;
            padding: 0;
            min-height: 280px;

    
        }

        .title {
            overflow: visible;
        }

        .logo_Francesco {
            font-size: 32px;
            width: 200px;
            overflow: visible;
            transform: translate(-40px, 42px);
            height: 50px;
            padding-top: 40px;
        
        }

        .logo_Savignano {
            font-size: 38px;
            width: 240px;
            overflow: visible;
            transform: translateY(32px);
            height: 60px;
        }

        .navigations {
            background-color: #d4b03a31;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            width: 100vw;
            height: 72px;
            margin: 0;
            transition: background-color 0.5s ease-in-out;
        }



        .socialNavigation {
            transform: translateY(40px);
        }
    }