body {
  margin: 0;
  padding: 0;
  width: 100vw;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

      
h1 {
  text-align: center;
}

h2 {
  font-family: 'trajan-pro-3', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  margin: 16px auto;
}

h3 {
  font-family: 'Krete', serif;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  margin: 16px auto;
}

h5 {
  font-family: 'Krete', serif;
  font-size: 24px;
  font-style: italic;
  margin: 16px auto;
}


.white {
  color: #fdfffa; 
}

p {
  font-family: 'Krete', serif;
  font-size: 18px;
  text-align: left;
  line-height: 36px;
  }

.scroll_blocked {
  height: 100vh;
  position: fixed;
}

@media only screen and (max-device-width:430px) {
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 24px;
  }

  h5 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
    line-height: 32px;
  }

  
}
